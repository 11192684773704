
import Vue from "vue";

import http from "@/http";

import FileDownload from "js-file-download";

import { BuildingResponse, Building, Store, Item, Company } from "@/interfaces";

import MyCard from "@/components/building/MyCard.vue";
import MyPager from "@/components/MyPager.vue";

import { mdiMagnify, mdiCloudDownload } from "@mdi/js";

export interface Query {
  keyword: string;
  page: number;
  store_id?: number;
  company_id?: number;
  is_obsolete: boolean;
}

export interface DataType {
  buildings: Building[];
  query: Query;
  page: number;
  hasPrev: boolean;
  hasNext: boolean;
}

export default Vue.extend({
  components: {
    MyCard,
    MyPager,
  },
  data(): DataType {
    return {
      buildings: [],
      query: {
        keyword: "",
        page: 0,
        store_id: undefined,
        company_id: undefined,
        is_obsolete: false,
      },
      page: 0,
      hasPrev: false,
      hasNext: false,
    };
  },
  computed: {
    icon() {
      return {
        mdiMagnify,
        mdiCloudDownload,
      };
    },
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "建物",
          disabled: true,
          to: "/buildings",
        },
      ];
    },
    items(): Item[] {
      return [
        { value: 1, text: "受付日" },
        { value: 2, text: "解約日" },
      ];
    },
    locks(): Item[] {
      return [
        { value: 0, text: "不可" },
        { value: 1, text: "可能" },
      ];
    },
    companies(): Item[] {
      return this.$store.state.companies
        .map((company: Company) => {
          return {
            text: company.name,
            value: company.id,
          };
        })
        .concat([
          {
            text: "設定なし",
            value: -1,
          },
        ]);
    },
    stores(): Item[] {
      return this.$store.state.stores.map((store: Store) => {
        return {
          text: store.name,
          value: store.id,
        };
      });
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async submit() {
      await this.$router
        .replace({
          path: "/buildings",
          query: {
            keyword: this.query.keyword,
            page: this.query.page.toString(),
            store_id: this.query.store_id?.toString(),
            company_id: this.query.company_id?.toString(),
            is_obsolete: this.query.is_obsolete.toString(),
          },
        })
        .catch(() => {});
    },
    async next() {
      const page = +this.query.page;

      this.query.page = page + 1;

      await this.submit();
    },

    async prev() {
      const page = +this.query.page;

      this.query.page = page - 1;

      await this.submit();
    },
    handleQuery() {
      const { keyword, page, store_id, company_id, is_obsolete } =
        this.$route.query;

      this.query = {
        keyword: keyword ? keyword.toString() : "",
        page: page ? +page : 1,
        store_id: store_id ? +store_id : undefined,
        company_id: company_id ? +company_id : undefined,
        is_obsolete: is_obsolete === "true",
      };
    },
    async fetchData() {
      this.handleQuery();

      const { data } = await http.get<BuildingResponse>("buildings", {
        params: {
          ...this.query,
          is_obsolete: this.query.is_obsolete ? "1" : "0",
        },
      });

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;

      this.buildings = data.data;
    },
    async click() {
      const { data } = await http.get("download/buildings", {
        params: {
          ...this.query,
        },
        responseType: "blob",
      });

      FileDownload(data, "buildings.xlsx");
    },
  },
});
