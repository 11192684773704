
import Vue, { PropType } from "vue";

import { mapState } from "vuex";

import { Building } from "@/interfaces";

import MyStreetAddress from "@/components/building/MyStreetAddress.vue";
import MyLifeline from "@/components/building/MyLifeline.vue";
import MyLock from "@/components/lock/MyLock.vue";

import http from "@/http";

export default Vue.extend({
  components: {
    MyStreetAddress,
    MyLifeline,
    MyLock
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      company_id: 0
    };
  },
  computed: {
    ...mapState(["companies"])
  },
  mounted() {
    if (this.building.subcontractor) {
      this.company_id = this.building.subcontractor.company_id;
    }
  },
  methods: {
    async change() {
      this.dialog = false;

      const url = `buildings/${this.building.id}`;

      const { data } = await http.put(url, {
        company_id: this.company_id
      });

      this.building.subcontractor = data.subcontractor;
    }
  }
});
